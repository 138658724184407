import { Box, CircularProgress } from "@mui/material"

export type SpinnerProps = {
  transparent?: boolean
}

export const Spinner = ({ transparent }: SpinnerProps) => {
  return (<Box
    height="100%"
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: transparent ? "transparent" : "rgba(255, 255, 255, 0.5)",
    }}
  >
    <CircularProgress />
  </Box>
  )  
}
